import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserService } from '../../../services/user.service' 

@Component({
  selector: 'app-pending-orders-action',
  templateUrl: './pending-orders-action.component.html',
  styleUrls: ['./pending-orders-action.component.css']
})
export class PendingOrdersActionComponent implements OnInit, ICellRendererAngularComp {
  @Output() priceSubmitted = new EventEmitter<{ price: number; orderId: number }>();

  public params: any;
  modalVisible: boolean = false;
  modalPosition = { top: '0px', left: '0px' };
  price: number = 0;
  userId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params['userId'];
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  deleteOrder() {
    
    if(confirm("Are you sure to delete the order no: " + this.params.data.index + "?")) {
      this.userService.deletePendingOrders(this.userId, this.params.data.index).subscribe(res => {
        if(res.success) {
          alert('Successfully deleted the order!');
        } else {
          alert('Error while deleting the order!');
        }

        window.location.reload();
      })
    }
  }

  showPriceModal(event: MouseEvent): void {
    this.modalVisible = true;
  
    // Calculate the position of the button
    const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
    const modalHeight = 80; // Approximate height of the modal (adjust if needed)
  
    // Adjust the position to make the modal appear above or below
    const positionAbove = buttonRect.top - modalHeight > 0; // Check if there's enough space above
    this.modalPosition = {
      top: positionAbove
        ? `${buttonRect.top + window.scrollY - modalHeight - 15}px` // Above the button
        : `${buttonRect.bottom + window.scrollY + 5}px`, // Below the button
      left: `${buttonRect.left + window.scrollX + 200}px`, // Align with the left edge of the button
    };
  }
  

  closeModal(): void {
    this.modalVisible = false;
    this.price = 0;
  }

  submitPrice(): void {
    console.log('price1:' , this.price)
    let filledPrice = this.price
    this.priceSubmitted.emit({ price: this.price, orderId: this.params.data.index });
    this.closeModal();

    this.userService.resolvePendingOrders(this.userId, this.params.data.index, filledPrice).subscribe(res => {
      if(res.success) {
        alert('Successfully set order filled price!');
      } else {
        alert('Error while setting order filled price!');
      }

      window.location.reload();
    })


  }

}
