import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Workbook } from 'exceljs';
import { DatePipe } from '@angular/common';
import * as logoFile from './logo.js';


@Component({
  selector: 'app-user-wallets',
  templateUrl: './user-wallets-new.component.html',
  styleUrls: ['./user-wallets-new.component.css']
})
export class UserWalletsComponentNew implements OnInit {

  data: any[] = [];

  rowData: any;
  walletInfoDate: any;
  columnDefs;
  daterange;
  endDate;
  extra_charge;
  current_wallet;
  inital_balance;
  set_wallet_name;
  userId;
  gridApi;
  frameworkComponents;
  balanceStatus;
  fundTransfers: any[];
  username: string;
  walletBalances: { [key: string]: number } = {};
  newWalletBalances: { [key: string]: number } = {};
  initialWalletBalances: { [key: string]: number } = {};
  walletEndDates: { [key: string]: Date } = {};
  walletNames: string[] = [];

  title = 'Trading Report';
  header = [
    { header: "", key: 'symbol', width: 15 },
    { header: "", key: 'createtime', width: 15 },
    { header: "", key: 'volume', width: 15 },
    { header: "", key: 'price', width: 15 },
    { header: "", key: 'ai_status', width: 15 },
    { header: "", key: 'fees', width: 15 },
    { header: "", key: 'extra_charge', width: 25 },
    { header: "", key: 'basis', width: 25 },
    { header: "", key: 'RPL', width: 30 },
    { header: "", key: 'wallet_name', width: 30 }
  ];


  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private datePipe: DatePipe) {
    this.userId = this.activatedRoute.snapshot.params['userId'];
    this.username = this.activatedRoute.snapshot.params['username'];
    this.extra_charge = 1.86;

  }

  ngOnInit() {
    this.getUserWallets(this.userId);
    this.fetchFundTransfers(this.userId);
    this.columnDefs = [
      // {
      //   headerName: "Id",
      //   field: 'id',
      //   width: 140,
      // },
      {
        headerName: "Wallet Name",
        field: 'wallet_name',
        width: 360,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Balance",
        field: 'wallet_balance',
        width: 525,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Balance Status",
        field: 'balance_status',
        width: 300,
        filter: true,
        sortable: true,
        resizable: true
      },
    ];
    this.getUserHistory(this.userId);

  }

  getUserHistory(userId: number) {
    this.userService.getUserHistory(userId).subscribe(
      (data) => {
        this.data = data;  // Store user history in the data[] array
      },
      (error) => {
        console.error('Error fetching user history:', error);
      }
    );
  }


  fetchFundTransfers(userId: number) {
    this.userService.getFundTransfersByUserId(userId).subscribe({
      next: (transfers) => {
        this.fundTransfers = transfers;  // Save the data to `fundTransfers`
        console.log('This 1. rowData', this.rowData)
        this.calculateWalletBalances();
      },
      error: (err) => {
        console.error('Error fetching fund transfers:', err);
      }
    });
  }

  calculateWalletBalances() {
    this.walletBalances = {};  // To store balances for each wallet

    this.fundTransfers.forEach((transfer: any) => {
      const { wallet_name, type, action, amount } = transfer;

      // Initialize the balance for the wallet if not already present
      if (!this.walletBalances[wallet_name]) {
        this.walletBalances[wallet_name] = 0;
      }

      const numericAmount = parseFloat(amount);

      // Check if the action is approved
      if (action === 'APPROVED') {
        if (type === 'DEPOSIT') {
          // Add the amount to the balance for deposits
          this.walletBalances[wallet_name] += numericAmount;
        } else if (type === 'WITHDRAW') {
          // Subtract the amount from the balance for withdrawals
          this.walletBalances[wallet_name] -= numericAmount;
        }
      }
    });

    this.walletNames = Object.keys(this.walletBalances);

    this.userService.getWalletDetailsByUserId(this.userId).subscribe({
      next: (walletDetails) => {
        const walletData = walletDetails.data[0];
        walletDetails.data
          .filter(wallet => wallet.wallet_name)  // Remove rows with empty wallet_name
          .forEach(wallet => {
            this.initialWalletBalances[wallet.wallet_name] = parseFloat(wallet.balance);

            this.walletEndDates[wallet.wallet_name] = new Date(wallet.end_date);
            this.walletBalances[wallet.wallet_name] = this.initialWalletBalances[wallet.wallet_name];
          });

        // Log to verify the results
        if (!this.endDate) {
          Object.keys(this.walletEndDates).forEach(walletName => {
            this.current_wallet = walletName;  // Set the current wallet to the wallet name
            this.endDate = this.walletEndDates[walletName];  // Set the endDate to the wallet's end date
            console.log(`Setting current wallet: ${this.current_wallet} with end date: ${this.endDate}`);
            this.setDateRange();  // Call setDateRange for each wallet
          });
        } else {
          this.setDateRange()
        }

        this.updateWalletDataForGrid();
        //this.inital_balance = parseFloat(walletData.balance);  // Assign the balance

      },
      error: (err) => {
        console.error('Error fetching wallet details:', err);
      }
    });
  }

  setDateRange(): void {
    if (this.set_wallet_name) {
      this.walletBalances[this.set_wallet_name] = this.inital_balance;
    }
    this.daterange = this.endDate
    if (!this.daterange || this.daterange.length === 0) {
      console.error('End date is not available in daterange.');
      return;
    }

    const endDate = new Date(this.daterange);
    console.log('Received end date:', endDate);
    // Set the start date as the next day from the given end date at 12:00 AM
    const startDate = new Date(endDate);
    // startDate.setDate(endDate.getDate() + 1);
    // startDate.setHours(0, 0, 0, 0);  // Set time to 12:00 AM
    console.log('Start Date: new', startDate)

    // Set the current date as today's date at 11:59 PM
    const today = new Date();
    today.setHours(23, 59, 59, 999);  // Set time to 11:59 PM

    // Set the date range
    this.daterange = [startDate, today];
    this.calculateWalletBalancesByDate()
    this.generateReportsSequentially()

  }

  calculateWalletBalancesByDate() {
    this.newWalletBalances = {};

    // Loop through each fund transfer
    this.fundTransfers.forEach((transfer: any) => {
      const { wallet_name, type, action, amount, createtime } = transfer;

      // Convert createtime to a Date object
      const transferDate = new Date(createtime);
      if (!this.newWalletBalances[wallet_name]) {
        this.newWalletBalances[wallet_name] = 0;
      }

      // Check if the transfer date is within the selected date range
      const startDate = this.daterange[0];
      const endDate = this.daterange[1];

      if (transferDate >= startDate && transferDate <= endDate) {
        console.log(`Transfer Date: ${transferDate} is within range ${startDate} - ${endDate}`);

        // Initialize the balance for the wallet if not already present


        const numericAmount = parseFloat(amount);

        // Check if the action is approved
        if (action === 'APPROVED') {
          if (type === 'DEPOSIT') {
            // Add the amount to the balance for deposits
            this.newWalletBalances[wallet_name] += numericAmount;
          } else if (type === 'WITHDRAW') {
            // Subtract the amount from the balance for withdrawals
            this.newWalletBalances[wallet_name] -= numericAmount;
          }
        }
      }
    });

  }


  generateReportsSequentially() {
    // Call the function with the current date range
    this.generateAndStoreReport(true).then((currentFileName) => {
      // // Calculate the previous month's date range

      // endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());

      const currentStartDate = new Date(this.daterange[0]);
      const currentEndDate = new Date(this.daterange[1]);

      // Clone the current start date and adjust to the previous month
      const startDate = new Date('2018-01-01');

      const endDate = new Date(currentStartDate.getFullYear(), currentStartDate.getMonth(), 0)

      // Set the adjusted date range
      this.daterange = [startDate, endDate];


      // Call the function again with the new date range
      this.generateAndStoreReport(false).then((previousFileName) => {
        this.userService.generateWallet(currentFileName, previousFileName).subscribe({
          next: (pdfResponse) => {

            if (pdfResponse && pdfResponse.walletsBasis) {
              // Loop through the walletsBasis and update walletBalances
              for (let walletName in pdfResponse.walletsBasis) {
                if (pdfResponse.walletsBasis.hasOwnProperty(walletName)) {
                  const basis = pdfResponse.walletsBasis[walletName];

                  if (walletName == '') {
                    continue
                  }

                  // If the wallet already exists in walletBalances, sum the basis
                  if (this.walletBalances[walletName]) {
                    if (this.set_wallet_name == walletName) {
                      let initial_balance = parseFloat(this.inital_balance)
                      this.walletBalances[walletName] = basis + initial_balance + this.newWalletBalances[walletName];

                      walletName = this.set_wallet_name
                      this.balanceStatus = "Set"

                      let walletHash = null;

                      console.log('First 1')
                      
                      const walletData = this.rowData.find(wallet => wallet.wallet_name === walletName);

                      if (walletData) {
                        walletHash = walletData.wallethash;
                        console.log('Wallet Name:' , walletHash)
                      }

                      this.userService.updateWalletBalance(walletName, initial_balance, this.userId, this.endDate, walletHash)
                        .subscribe({
                          next: (response) => {
                            console.log('Wallet balance updated successfully:', response);
                          },
                          error: (error) => {
                            console.error('Error updating wallet balance:', error);
                          }
                        });
                      break;
                    } else if (walletName == this.current_wallet) {
                      this.walletBalances[walletName] = basis + this.initialWalletBalances[walletName] + this.newWalletBalances[walletName];
                      this.balanceStatus = "Set"
                    }
                  } else {
                    let initial_balance = parseFloat(this.inital_balance);
                    this.walletBalances[walletName] = basis + initial_balance;

                    if (this.set_wallet_name) {
                      console.log('Select Wallet name', this.set_wallet_name)
                    }

                    console.log('this.walletBalances[walletName]', this.walletBalances[walletName])
                    console.log('Second 2')
                    let walletHash = null;
                    const walletData = this.rowData.find(wallet => wallet.wallet_name === walletName);

                    if (walletData) {
                      walletHash = walletData.wallethash;
                    }
                    this.userService.updateWalletBalance(walletName, this.inital_balance, this.userId, this.endDate, walletHash)
                      .subscribe({
                        next: (response) => {
                          console.log('Wallet balance updated successfully:', response);
                        },
                        error: (error) => {
                          console.error('Error updating wallet balance:', error);
                        }
                      });

                  }
                }
              }
              this.updateWalletDataForGrid();
            }

            //alert('Wallet balance generated successfully!');
          },
          error: (pdfError) => {
            console.error('Error generating PDF:', pdfError);
            //alert('Failed to generate PDF!');
          }
        });
      }).catch((error) => {
        console.error('Error during report generation:', error);
      });


    }).catch((error) => {
      if (this.set_wallet_name && this.inital_balance) {
        let intial_balance = parseFloat(this.inital_balance)
        this.walletBalances[this.set_wallet_name] = intial_balance + this.newWalletBalances[this.set_wallet_name];
        this.balanceStatus = "Set"
        let walletHash = null;
        console.log('Third 1')
        console.log('This row data:' , this.walletInfoDate)
        console.log('Wallet Name' , this.set_wallet_name)
        const walletData = this.walletInfoDate.find(wallet => wallet.wallet_name === this.set_wallet_name);

        console.log('Wallet Data' , walletData)

        if (walletData) {
          walletHash = walletData.wallethash;
        }
        
        this.userService.updateWalletBalance(this.set_wallet_name, this.inital_balance, this.userId, this.endDate, walletHash)
          .subscribe({
            next: (response) => {
              console.log('Wallet balance updated successfully 1:', response);
              this.updateWalletDataForGrid();
            },
            error: (error) => {
              console.error('Error updating wallet balance:', error);
            }
          });



      }

      //console.error('Error during report generation 1:', error);
    });
  }

  generateAndStoreReport(firstCall: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {

      if (!this.daterange) {
        alert('Select Dates to continue');
        reject('Date range not selected.');
        return;
      }


      if (String(this.extra_charge) === "undefined" || isNaN(this.extra_charge)) {
        alert('Enter valid extra charge');
        reject('Invalid extra charge.');
        return;
      }

      this.extra_charge = parseFloat(this.extra_charge);

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Trading Report Data');

      let logo = workbook.addImage({
        base64: logoFile.logoBase64,
        extension: 'png',
      });
      worksheet.addImage(logo, 'A1:E5');
      worksheet.mergeCells('A1:E5');

      let cell_title = worksheet.getCell('B7');
      let cell_title_range = this.daterange[0].toDateString() === this.daterange[1].toDateString() ?
        this.datePipe.transform(this.daterange[0], 'dd-MM-yyyy').toString() :
        this.datePipe.transform(this.daterange[0], 'dd-MM-yyyy').toString() + ' To ' + this.datePipe.transform(this.daterange[1], 'dd-MM-yyyy').toString();
      cell_title.value = 'Activity Statement: ' + cell_title_range;
      cell_title.alignment = { vertical: 'middle', horizontal: 'center' };
      cell_title.font = { bold: true };
      worksheet.mergeCells('B7:D7');

      let cell_statement_id = worksheet.getCell('I5');
      cell_statement_id.value = new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString() + "-" + this.userId;
      cell_statement_id.font = { size: 16 };

      let cell_amount = worksheet.getCell('I6');
      cell_amount.value = 'Adjust Amount';
      cell_amount.font = { size: 16 };

      worksheet.addRow([]);

      worksheet.columns = this.header;
      let headerRow = worksheet.addRow({
        symbol: "Symbol",
        createtime: "Date/Time",
        volume: "Quantity",
        price: "T. Price",
        ai_status: "AI Status",
        fees: "Comm/Fee",
        extra_charge: "Third-Party Charge",
        basis: "Basis",
        RPL: "Realized P/L",
        wallet_name: "Wallet Name/Amount"
      });
      headerRow.height = 25;
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2CC' },
          bgColor: { argb: 'FFF2CC' }
        }
        cell.border = { top: { style: 'medium', color: { argb: 'DEDEDE' } }, left: { style: 'medium', color: { argb: 'DEDEDE' } }, bottom: { style: 'medium', color: { argb: 'DEDEDE' } }, right: { style: 'medium', color: { argb: 'DEDEDE' } } }
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      });

      worksheet.addRow({ symbol: "Stocks", wallet_name: "" })
        .eachCell({ includeEmpty: true }, (cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE699' },
            bgColor: { argb: 'FFE699' }
          }
        });

      let groupedHistory: Object = this.prepareData(this.data);

      if (!Object.keys(groupedHistory).length) {
        if (firstCall) {
          //alert('No data found for given range.');
          reject('No data found.');
        } else {
          resolve('');
        }

        return;
      }

      for (const currency in groupedHistory) {
        if (Object.prototype.hasOwnProperty.call(groupedHistory, currency)) {

          let rows = groupedHistory[currency];

          rows = rows.sort(function (a: any, b: any) {
            return a.symbol.localeCompare(b.symbol) || (a.id - b.id);
          })

          worksheet.addRow({ symbol: currency, wallet_name: "" })
            .eachCell({ includeEmpty: true }, (cell, number) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFF2CC' },
                bgColor: { argb: 'FFF2CC' }
              }
            });

          var totalFees = 0;
          var total_extra_charge = 0;
          var total_basis = 0;
          var total_RPL = 0;
          for (let i = 0; i < rows.length; i++) {
            rows[i].position_type = this.getPositionType(this.data, rows[i]);

            if (rows[i].action) {
              if (i > 0 && (rows[i].position_type == "B/C" || rows[i - 1].ai_status == "B/C")) {
                if (rows[i].position_type == "B/C") {
                  let allOrders = this.getSymbolHistory(this.data, rows[i].symbol).filter(row => row.action);
                  let currentOrderIndex = allOrders.findIndex(order => order.id === rows[i].id);
                  let openPositionRow = this.mapRow(allOrders[currentOrderIndex - 1]);
                  rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, "B/C", openPositionRow.volume, openPositionRow.price);
                } else {
                  rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, "B/C", rows[i - 2].volume, rows[i - 2].price);
                }

                rows[i].position_type = "B/C";
              } else {
                rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, rows[i].position_type, null, null);
              }

              if (rows[i].position_type == "B/C" || rows[i].position_type == "S/C") {
                let open_basis;
                let close_basis = rows[i].basis;

                let allOrders = this.getSymbolHistory(this.data, rows[i].symbol).filter(row => row.action);
                let currentOrderIndex = allOrders.findIndex(order => order.id === rows[i].id);
                if (currentOrderIndex <= 0) { // Check if the previous index is valid
                  continue; // Skip to the next iteration of the loop
                }
                let openPositionRow = this.mapRow(allOrders[currentOrderIndex - 1]);

                if (rows.findIndex(row => row.id === openPositionRow.id) === -1) {
                  open_basis = this.calculateBasis(openPositionRow.volume, openPositionRow.price, openPositionRow.fees, openPositionRow.extra_charge, openPositionRow.ai_status, null, null);
                } else {
                  let openPositionIndex = rows.findIndex(row => row.id === openPositionRow.id)
                  open_basis = rows[openPositionIndex].basis;
                }

                rows[i].RPL = close_basis + open_basis;

                if (openPositionRow.order_type == "AT") {
                  let atFees = this.calculateATFees(openPositionRow.price, rows[i].price, rows[i].volume);
                  if (atFees < 0) {
                    rows[i].additional_at_row = true;
                    rows[i].additional_at_row_basis = atFees * 0.1;
                  }
                }
              }
            }

            if (rows[i].position_type == "C/O") {
              worksheet.addRow(rows[i])
                .eachCell({ includeEmpty: true }, (cell, number) => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFE699' },
                    bgColor: { argb: 'FFE699' }
                  }
                });
            } else {
              worksheet.addRow(rows[i]);
            }

            if (rows[i].additional_at_row) {
              let at_row = {
                symbol: rows[i].symbol,
                createtime: rows[i].createtime,
                ai_status: "Auto Trading",
                basis: rows[i].additional_at_row_basis,
                wallet_name: rows[i].wallet_name,
              }
              worksheet.addRow(at_row);
            }

            if (i == rows.length - 1 || rows[i].symbol != rows[i + 1].symbol) {
              let { fees, extra_charge, basis, RPL } = this.getTotalsBySymbol(rows, rows[i].symbol);
              totalFees += fees;
              total_extra_charge += extra_charge;
              total_basis += basis;
              total_RPL += RPL;


              worksheet.addRow({
                symbol: "Total " + rows[i].symbol,
                wallet_name: "",
                fees: fees,
                extra_charge: extra_charge,
                basis: basis,
                RPL: RPL
              })
                .eachCell({ includeEmpty: true }, (cell, number) => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFF2CC' },
                    bgColor: { argb: 'FFF2CC' }
                  }
                });
            }

            if (i == rows.length - 1) {
              worksheet.addRow({
                symbol: "Total",
                wallet_name: "",
                fees: totalFees,
                extra_charge: total_extra_charge,
                basis: total_basis,
                RPL: total_RPL,
              })
                .eachCell({ includeEmpty: true }, (cell, number) => {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFE699' },
                    bgColor: { argb: 'FFE699' }
                  },
                    cell.font = {
                      bold: true
                    }
                });
            }
          }

          for (let i = 0; i < 5; i++) {
            worksheet.addRow([]);
          }
        }
      }


      const month = this.daterange[0].toLocaleString('default', { month: 'short' }).toLowerCase();
      const year = this.daterange[0].getFullYear();
      const fileName = `${this.userId}_${month}_${year}.xlsx`;


      workbook.xlsx.writeBuffer().then((data) => {
        const file = new File([data], fileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const formData = new FormData();
        formData.append('file', file);

        this.userService.saveReport(formData).subscribe(
          response => {
            resolve(fileName);
          },
          error => {
            console.error('Error uploading file', error);
            reject(error);
          }
        );
      });
    });
  }

  prepareData(data: any) {
    let result = [];

    let fromDate = this.daterange[0];
    let toDate = this.daterange[1];

    // Adjust fromDate to start of the day
    //fromDate.setHours(0, 0, 0, 0);

    // Adjust toDate to end of the day
    toDate.setHours(23, 59, 59, 999);

    // filter by date range
    // Daily Report
    if (fromDate.getTime() == toDate.getTime()) {
      data = data.filter((item: any) => {
        return new Date(item.createtime).toDateString() == fromDate.toDateString();
      });
    } else {
      data = data.filter((item: any) => {
        return new Date(item.createtime).getTime() >= fromDate.getTime() &&
          new Date(item.createtime).getTime() <= toDate.getTime();
      });
    }

    // sort by date - ASC
    data = data.sort(function (a: any, b: any) {
      return new Date(a.createtime).getTime() - new Date(b.createtime).getTime();
    })

    // model data
    for (var i = 0; i < data.length; i++) {

      // skip wallet Q
      if (data[i].wallet_name == "Q" || data[i].wallet_name == "q") {
        continue;
      }
      let row = this.mapRow(data[i]);

      // push row
      result.push(row);
    }

    // group data by the exchange
    result = result.reduce(function (r, a) {
      let currency = "NA";

      if (a.exchange == "NYSE" || a.exchange == "NASDAQ") {
        currency = "USD";
      }

      if (a.exchange == "TSE") {
        currency = "CAD";
      }

      if (a.exchange == "SEHK") {
        currency = "HKD";
      }

      if (a.exchange == "SHA" || a.exchange == "SHE" || a.exchange == "SEHKNTL") {
        currency = "CNY";
      }

      r[currency] = r[currency] || [];
      r[currency].push(a);
      return r;
    }, Object.create(null));

    return result;
  }


  calculateFees = (exchange, price, quantity) => {
    let baseCapital, commission;

    baseCapital = price * quantity;

    if (exchange == "NYSE" || exchange == "NASDAQ") {
      commission = ((0.008 * quantity > 1.5) ? (0.008 * quantity) : 1.5);
    } if (exchange == "TSE") {
      commission = ((0.015 * quantity > 1.5) ? (0.015 * quantity) : 1.5);
    } if (exchange == "SHE" || exchange == "SHA" || exchange == "SEHKNTL") {
      commission = ((0.001 * baseCapital > 20) ? 0.001 * baseCapital : 20);
    } if (exchange == "SEHK") {
      commission = ((0.001 * baseCapital > 25) ? 0.001 * baseCapital : 25);
    }

    return commission * -1;
  }

  calculateBasis = (volume, price, fees, third_party_charge, position, open_volume, open_price) => {
    if (position === "B/O" || position === "C/O" || position === "S/C") {
      return (price * volume - fees - third_party_charge) * -1;
    }
    else if (position === "S/O") {
      return (price * volume + fees + third_party_charge);
    }
    else if (position === "B/C") {
      return -2 * open_volume * open_price - price * volume + fees + third_party_charge;
    }
    else {
      return 0;
    }
  }


  calculateATFees = (open_price, close_price, close_volume) => {
    return (close_price - open_price) * close_volume;
  }

  getPositionType = (history, currentOrder) => {

    // check for AT order and return the position type
    if (currentOrder.ai_status) {
      // check for B/C or S/C
      let allOrders = this.getSymbolHistory(history, currentOrder.symbol);

      let currentOrderIndex = allOrders.findIndex(order => order.id === currentOrder.id);

      // get prev AT order
      let atOrders = allOrders.filter(order => order.order_type == "AT" && order.action && order.symbol == currentOrder.symbol);
      let prevATOrderIndex = atOrders.findIndex(order => order.id === currentOrder.id) - 1;

      // match consecutive B/O or S/O AT orders
      if (
        (currentOrder.ai_status == "B/O" || currentOrder.ai_status == "S/O") &&
        (prevATOrderIndex >= 0 && (atOrders[prevATOrderIndex].ai_status == "B/O" || atOrders[prevATOrderIndex].ai_status == "S/O") && currentOrder.ai_status != atOrders[prevATOrderIndex].ai_status)
      ) {
        return currentOrder.ai_status == "B/O" ? "B/C" : "S/C";
      }
      else {
        return currentOrder.ai_status
      }
    }

    // determine position type for MKT order
    // Filter MKT order by symbol and sort asc
    var orders = history.filter(row => row.symbol === currentOrder.symbol && row.action && row.volume && row.id < currentOrder.id).sort((a, b) => {
      return (a.id - b.id);
    });

    var netPosition = 0;

    for (var j = 0; j < orders.length; j++) {
      if (orders[j].action == "BUY") {
        netPosition = netPosition + orders[j].volume;
      }
      if (orders[j].action == "SELL") {
        netPosition = netPosition - orders[j].volume;
      }
    }

    if (netPosition == 0) {
      return currentOrder.action == "BUY" ? "B/O" : "S/O";
    } else {
      if (netPosition + currentOrder.volume == 0) {
        return currentOrder.action == "BUY" ? "B/C" : "S/C";
      }
      // close and open
      else if (Math.abs(netPosition) < Math.abs(currentOrder.volume)) {
        return "C/O"
      }
      else {
        return "B/O";
      }
    }
  }

  getSymbolHistory(history, symbol) {
    return history.filter(row => row.symbol === symbol).sort((a, b) => {
      return (a.id - b.id);
    });
  }

  mapRow(data) {

    if (!data) {
      console.error('mapRow called with null or undefined data');
      return null;  // Return null to signify that the mapping could not be performed
    }

    return {
      symbol: data.symbol,
      createtime: this.datePipe.transform(data.createtime, 'dd-MM-yyyy').toString(),
      volume: data.action == 'SELL' ? data.volume * -1 : data.volume,
      price: data.avg_fill_price,
      ai_status: data.ai_status,
      fees: this.calculateFees(data.exchange, data.avg_fill_price, data.volume),
      // NO extra charge for AI advice
      extra_charge: !data.action ? 0 : this.extra_charge,
      // -1.5 basis for AI advice 
      basis: (data.ai_status && !data.order_type) ? -1.5 : 0,
      RPL: 0,
      wallet_name: data.wallet_name,
      exchange: data.exchange,
      position_type: "",
      id: data.id,
      action: data.action,
      order_type: data.order_type,
      additional_at_row: false,
      additional_at_row_basis: 0
    }
  }

  getTotalsBySymbol = (rows, symbol) => {
    // filter by symbol
    rows = rows.filter(row => row.symbol == symbol);

    return {
      fees: rows.map(row => row.fees).reduce((acc, fees) => acc + fees, 0),
      extra_charge: rows.map(row => row.extra_charge).reduce((acc, extra_charge) => acc + extra_charge, 0),
      // add basis + calculated AT fees basis 
      basis: rows.map(row => row.basis).reduce((acc, basis) => acc + basis, 0) + rows.map(row => row.additional_at_row_basis).reduce((acc, basis) => acc + basis, 0),
      RPL: rows.map(row => row.RPL).reduce((acc, RPL) => acc + RPL, 0)
    };
  }


  updateWalletDataForGrid() {
    // Transform walletBalances into an array of objects for the grid

    this.rowData = Object.keys(this.walletBalances).map(walletName => {

      if (!walletName || walletName === 'undefined') {
        return null;  // Return null for rows that should be excluded
      }

      let balanceStatus;

      // Check both conditions:
      if (this.set_wallet_name == walletName || walletName in this.initialWalletBalances) {
        balanceStatus = this.balanceStatus;  // Set to "Updated balance" or whatever status you want
      } else {
        balanceStatus = 'Not set';  // Default status when conditions are not met
      }

      if (walletName in this.initialWalletBalances) {
        balanceStatus = 'set'
      }
      return {
        wallet_name: walletName,
        wallet_balance: this.walletBalances[walletName],
        balance_status: balanceStatus
      };
    });

    // Optionally refresh the grid
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
  }

  getUserWallets(userId: number) {
    this.userService.getUserWallet(userId).subscribe(data => {
      this.rowData = data;
      this.walletInfoDate = data;
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
  }

}
